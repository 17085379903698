@use '@angular/material' as mat;
@mixin login-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  login {
    #login {
      background-color: mat.get-color-from-palette($fuse-navy, 700);
      #login-form-wrapper {
        #login-form {
          @if ($is-dark) {
            background: mat.get-color-from-palette($fuse-navy, 600);
          } @else {
            background: map-get($background, card);
          }

          .separator {
            color: map-get($foreground, divider);

            .text {
              &:before,
              &:after {
                border-top-color: map-get($foreground, divider);
              }
            }
          }
        }
      }
    }
  }
}
