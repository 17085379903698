// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";

@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

@font-face {
  font-family: "customFont";
  src: url("assets/fonts/TMedium.ttf") format('truetype');
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: "customFont";
  src: url("assets/fonts/TBold.ttf") format('truetype');
  font-display: swap;
  font-weight: bold;
}

@font-face {
  font-family: "customFontHead";
  src: url("assets/fonts/TBoldSpecial.ttf") format('truetype');
  font-display: swap;
  font-weight: bold;
}

* {
  font-family: "customFont";
  font-size: 2rem;
}

.dot-border {
  height: 22px;
  width: 22px;
  border: solid 2px;
  border-radius: 50%;
  display: block;
  margin: auto;
}

.dot-red {
  height: 22px;
  width: 22px;
  background-color: #ed1c24;
  border-radius: 50%;
  display: block;
  margin: auto;
}

.dot-yellow {
  height: 22px;
  width: 22px;
  background-color: #fcee21;
  border-radius: 50%;
  display: block;
  margin: auto;
}

.dot-green {
  height: 22px;
  width: 22px;
  background-color: #009245;
  border-radius: 50%;
  display: block;
  margin: auto;
}

.disable-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome and Opera */
}

.cover-image {
  background-repeat: no-repeat !important;
  -webkit-background-size: contain !important;
  -moz-background-size: contain !important;
  -o-background-size: contain !important;
  background-size: contain !important;
  background-position: center !important;
  height: 240px;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

// @keyframes fadein {
//   from {
//     background: transparent;
//   }
//   to {
//     background: #ffffff;
//   }
// }

// @keyframes fadeout {
//   from {
//     background: #ffffff;
//   }
//   to {
//     background: transparent;
//   }
// }

// .skeleton-content {
//   width: 100%;
//   height: 12px;
//   background: #ffffff;
//   border-radius: 2px;
//   margin: 4px;

//   animation: fadein .7s;
// }

// .skeleton-content-out {
//   animation: fadeout .7s;
// }

.filter-box-container {
  position: relative;
  display: inline-block;
  background-color: transparent;
  border: 1px solid #fff;

  &.active {
    .content {
      display: block;
      width: 100%;
    }
  }

  .filter-title {
    color: white;
    font-weight: bold;
    user-select: none;
  }

  .content {
    display: none;
    position: relative;
    // z-index: 9999;
    min-width: 160px;
    background-color: rgba(38, 38, 38, .85);
    padding: 10px 0;
  }
}
