@use '@angular/material' as mat;
@mixin forgot-password-theme($theme) {
  $background: map-get($theme, background);
  $is-dark: map-get($theme, is-dark);

  forgot-password {
    #forgot-password {
      background-color: mat.get-color-from-palette($fuse-navy, 700);
      #forgot-password-form-wrapper {
        #forgot-password-form {
          @if ($is-dark) {
            background: mat.get-color-from-palette($fuse-navy, 600);
          } @else {
            background: map-get($background, card);
          }
        }
      }
    }
  }
}
